import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  /*
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
  },
  */
  {
    path: 'cart-summary',
    loadChildren: () => import('./cart/cart-summary/cart-summary.module').then( m => m.CartSummaryPageModule)
  },
  {
    path: 'cart-creneau',
    loadChildren: () => import('./cart/cart-creneau/cart-creneau.module').then( m => m.CartCreneauPageModule)
  },
  {
    path: 'cart-payment',
    loadChildren: () => import('./cart/cart-payment/cart-payment.module').then( m => m.CartPaymentPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'legales/:step',
    loadChildren: () => import('./legales/legales.module').then( m => m.LegalesPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
  },
  {
    path: 'params',
    loadChildren: () => import('./params/params.module').then( m => m.ParamsPageModule)
  },
  {
    path: 'admin-delivery',
    loadChildren: () => import('./admin-delivery/admin-delivery.module').then( m => m.AdminDeliveryPageModule)
  },
  {
    path: 'redirect-app',
    loadChildren: () => import('./redirect-app/redirect-app.module').then( m => m.RedirectAppPageModule)
  },
  {
    path: 'login-delete',
    loadChildren: () => import('./login-delete/login-delete.module').then( m => m.LoginDeletePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
