import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private products;
  private fidelity;
  public order;
  public delivery_address_id;
  private comment;
  private id_store_address;

  constructor(private storage : Storage) {
    this.products = [];
    this.fidelity = false;
    this.order = undefined;
    this.delivery_address_id = undefined;
    this.id_store_address = 0;
  }

  getComment(){ return this.comment; }

  setComment(nComment){ this.comment = nComment; }
  
  setIdStoreAddress(nIdStoreAddress){ this.id_store_address = nIdStoreAddress; }

  getIdStoreAddress(){ return this.id_store_address; }


  async init(id_store){
    this.storage.create();
    var products = await this.storage.get('products');
    if (products) {
      var productsTab = JSON.parse(products);
      var ret = [];
      for (const product of productsTab) {
        if (product.id_store == id_store) {
          ret.push(product);
        }
        this.products = ret;
      }
    }
  }

  getProduct(){
    return this.products
  }

  getProductsId(){
    var ret = [];
    for (const one of this.products) {
      var n = {
        id_product : one.id_product,
        declination : undefined,
      }
      if (one.declination && one.declination.id_declination) {
        n.declination = one.declination.id_declination
      }
      ret.push(n)
    }
    return this.products
  }

  setFidelity(nFidelity){ this.fidelity = nFidelity; }

  addToCart(nProduct, declination = undefined, supplements=undefined){
    nProduct.quantity = 1;
    if (declination)
      nProduct.declination = declination;
    if (supplements) {
      nProduct.supplements = supplements;
      var total_supp = 0.0;
      for (const supp of supplements) {
        total_supp = total_supp + parseFloat(supp.price_ttc);
      }
      nProduct.total_supplement = total_supp.toFixed(2);
    }

    nProduct.total_ttc = (nProduct.declination) ? +nProduct.declination.price_ttc : +nProduct.price_ttc;
    nProduct.total_ttc += +nProduct.total_supplement;
    nProduct.total_ttc = nProduct.total_ttc.toFixed(2)
    this.products.push(nProduct)
    this.storage.set('products', JSON.stringify(this.products));
  }

  getTotalTTC(){
    var total = 0;
    for (var product of this.products) {
      if (product.declination) {
        total = total + parseFloat(product.declination.price_ttc)
      } else {
        total = total + parseFloat(product.price_ttc)
      }
      if (product.total_supplement) {
        total = total + parseFloat(product.total_supplement);
      }
    }

    return total.toFixed(2);
  }

  getTotalTTCAndFidelity(delivery_cost=0){
    return (Number(Number(+this.getTotalTTC() - +this.getProductLessCost()).toFixed(2)) + Number(delivery_cost)).toFixed(2);
  }

  getProductLessCost() {
    if (!this.fidelity)
      return 0.0;
    var less = 0.0;
    var ind = -1;
    var i = 0;
    for (var product of this.products) {
      product.free = 0;
      if (product.preparable) {
        if (!less || less > product.total_ttc) {
          less = product.total_ttc;
          ind = i;
        }
      }
      i++;
    }
    if (ind != -1) {
      this.products[ind].free = 1;
    }
    return Number(less).toFixed(2);
  }

  deleteProduct(index){
    this.products.splice(index, 1);
    this.storage.set('products', JSON.stringify(this.products));
  }

  empty(){
    this.products = [];
    this.fidelity = false;
    this.order = undefined;
    this.storage.remove('products')
  }

  //This takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  calcCrow(lat1, lon1, lat2, lon2)
  {
    var R = 6371; // km
    var dLat = this.toRad(lat2-lat1);
    var dLon = this.toRad(lon2-lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c;
    return d.toFixed(1);
  }

  // Converts numeric degrees to radians
  toRad(Value)
  {
    return Value * Math.PI / 180;
  }
}
