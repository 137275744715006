import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  //private url = 'http://localhost:8299/' ;
  private url = 'https://api.pizz-app.fr/' ;
  private id_store = 1;
  public user;
  public configuration;
  public categories;
  public products;
  public addPage;
  public banner;
  public drive_configuration;

  constructor(private http : HttpClient, private storage : Storage) {
    this.storage.create();
    this.init();
    this.configuration = {
      background_color : '#006c36',
      color : '#d62e2e',
      card_color : '',
      card_background : '',
      button_color : '',
      button_background : '',
    }
    this.products = [];
    this.categories = [];
  }

  getIdStore(){ return this.id_store; }

  async init(){
    this.addPage = [
      { title: 'Menu', url: '/', icon: 'restaurant' },
      { title: 'Panier', url: '/cart-summary', icon: 'cart' },
      { title: 'Informations', url: '/contact', icon: 'storefront' },
      { title: 'Mon compte', url: '/login', icon: 'person' },
      { title: 'Infos légales', url: '/legales/0', icon: 'documents' },
    ];

  }

  /**
   * addAdminPage
   */
  public addAdminPage() {
    for (const page of this.addPage) {
      if (page.title == "Cuisinier") {
        return ;
      }
    }
    if(this.user.admin == 1){
      this.addPage.push({ title: 'Cuisinier', url: '/admin', icon: 'flame' })
      this.addPage.push({ title: 'Livreur', url: '/admin-delivery', icon: 'car-sport' })
    }
  }

  private _genAuthHeader(token = undefined){
    if (token) {
      return {
        headers: new HttpHeaders().append('Authorization',  token)
      }
    }
    return {
      headers: new HttpHeaders().append('Authorization',  (this.user && this.user.token) ? this.user.token : '')
    }
  }

  setCategories(pkg){
    this.categories = pkg
  }

  getCategoryById(idCat){
    for (const cat of this.categories) {
      if (cat.id_category == idCat)
        return cat;
    }
    return undefined;
  }

  setProducts(pkg){
    this.products = pkg
  }
  getLocalCategories(){
    return this.categories;
  }
  /* Products */
  getCategories() : Observable<any>{
    return this.http.get(this.url + 'category/get/' + this.id_store, this._genAuthHeader()).pipe(
      map(results => results),
    );
  }
  getProducts() : Observable<any>{
    return this.http.get(this.url + 'product/get/' + this.id_store, this._genAuthHeader()).pipe(
      map(results => results)
    );
  }

  sendPrinter() : Observable<any>{
    return this.http.post('ws://192.168.1.16:9100', {t : "Hello world"}).pipe(
      map(results => results)
    );
  }

  getProductsByIdCategory(id_category) : Observable<any>{
    return this.http.get(this.url + 'product/get-by-id-category/' + id_category, this._genAuthHeader()).pipe(
      map(results => results)
    );
  }

  getProductDetails(id_product) : Observable<any>{
    return this.http.get(this.url + 'product/get-details/' + id_product, this._genAuthHeader()).pipe(
      map(results => results)
    );
  }

  /* Store */
  getStore() : Observable<any>{
    return this.http.get(this.url + 'store/get/app/' + this.id_store, this._genAuthHeader()).pipe(
      map(results => results)
    );
  }

  checkUserAddress(id_address) : Observable<any>{
    var pkg = {
      id_address : id_address
    }
    return this.http.post(this.url + 'store/check-user-address', pkg, this._genAuthHeader()).pipe(
      map(results => results)
    );
  }
  /* User */

  /* pkg : {email : xxxxx, password : xxxx} */
  login(pkg) : Observable<any>{
    return this.http.post(this.url + 'user/login/' + this.id_store, pkg, this._genAuthHeader()).pipe(
      map(results => results)
    );
  }
  /* pkg : {email : xxxxx, password : xxxx} */
  loginToken(id_user, token) : Observable<any>{
    var pkg = {
      id_user : id_user,
      token : token
    };
    return this.http.post(this.url + 'user/login-token/' + this.id_store, pkg, this._genAuthHeader(token)).pipe(
      map(results => results)
    );
  }

  signup(pkg) : Observable<any>{
    return this.http.post(this.url + 'user/signup/' + this.id_store, pkg, this._genAuthHeader()).pipe(
      map(results => results)
    );
  }

  editProfile(pkg) : Observable<any>{
    let header = this._genAuthHeader();
    return this.http.post(this.url + 'user/edit-profile', pkg, this._genAuthHeader()).pipe(
      map(results => results)
    );
  }

  saveUser(){
    if(this.user.admin == 1){
      if (!this.addPage.find(elt => elt.title == 'Cuisinier')) {
        this.addPage.push({ title: 'Cuisinier', url: '/admin', icon: 'flame' })
      }
      if (!this.addPage.find(elt => elt.title == 'Livreur')) {
        this.addPage.push({ title: 'Livreur', url: '/admin-delivery', icon: 'car-sport' })
      }
    }
    this.storage.set('user', JSON.stringify(this.user));
  }

  deconection () {
    if (this.user.admin == 1) {
      this.addPage.pop()
    }
    this.storage.remove('user')
    this.user = null
  }

  userDelete(){
    let header = this._genAuthHeader();
    return this.http.post(this.url + 'user/delete-profile', {}, this._genAuthHeader()).pipe(
      map(results => results)
    );
  }

  //Drive configuration
  getDriveConfiguration(){
    return this.http.get(this.url + 'drive-configuration/get-app/' + this.id_store).pipe(
      map(results => results)
    );
  }

  getCreneaux(date, preparable) : Observable<any>{
    let header = this._genAuthHeader();
    let sqlDate = new Date(date);
    let sqlDateStr = sqlDate.getFullYear() + "-" + (sqlDate.getMonth() +1) + "-" +sqlDate.getDate();
    return this.http.post(this.url + 'drive-configuration/get-crenaux/' + this.id_store + '/' + preparable, {date : sqlDateStr}, header).pipe(
      map(results => results)
    );
  }
  reserveCreneaux(date_creneau, hour_creneau, min_creneau, products, id_delivery_address, comment, id_store_address) : Observable<any>{
    let header = this._genAuthHeader();
    let pkg = {
      date_creneau : date_creneau,
      hour_creneau : hour_creneau,
      min_creneau : min_creneau,
      cart : products,
      id_delivery_address : id_delivery_address,
      comment : comment,
      id_store_address : id_store_address
    }
    return this.http.post(this.url + 'drive-configuration/reserve-creneaux/' + this.id_store, pkg, header).pipe(
      map(results => results)
    );
  }

  //
  //Order
  //
  adminGetOrders(id_store_address) : Observable<any>{
    return this.http.get(this.url + 'order/app/get-admin/' + id_store_address, this._genAuthHeader()).pipe(
      map(results => results)
    );
  }

  adminGetOrdersDelivery(id_store_address) : Observable<any>{
    return this.http.get(this.url + 'order/app/get-admin-delivery/' + id_store_address, this._genAuthHeader()).pipe(
      map(results => results)
    );
  }

  adminSetOrderDelivered(id_order) : Observable<any>{
    return this.http.get(this.url + 'order/app/admin-order-delivered/'+id_order, this._genAuthHeader()).pipe(
      map(results => results)
    );
  }

  userGetOrders() : Observable<any>{
    let header = this._genAuthHeader();
    return this.http.get(this.url + 'order/app/get-user/' + this.id_store, header).pipe(
      map(results => results)
    );
  }

  orderGetDetail(id_order) : Observable<any>{
    let header = this._genAuthHeader();
    return this.http.get(this.url + 'order/app/get-user-details/' + this.id_store + "/" + id_order, header).pipe(
      map(results => results)
    );
  }

  orderSetPaid(id_order){
    let header = this._genAuthHeader();
    return this.http.get(this.url + 'order/app/order-paid-paypal/'+ this.id_store +'/' + id_order, header).pipe(
      map(results => results)
    );
  }

  payWithStripe(pkg) : Observable<any>{
    let header = this._genAuthHeader();
    return this.http.post(this.url + 'order/payment-stripe',pkg,  header).pipe(
      map(results => results)
    );
  }
  payConfirmWithStripe(pkg) : Observable<any>{
    let header = this._genAuthHeader();
    return this.http.post(this.url + 'order/payment-stripe/confirm',pkg,  header).pipe(
      map(results => results)
    );
  }
  payOnRecup(pkg) : Observable<any>{
    let header = this._genAuthHeader();
    return this.http.post(this.url + 'order/payment-recup', pkg, header).pipe(
      map(results => results)
    );
  }

  paymentFreeFidelity(pkg) : Observable<any>{
    let header = this._genAuthHeader();
    return this.http.post(this.url + 'order/payment-free-fidelity', pkg, header).pipe(
      map(results => results)
    );
  }
  //Publicity
  getBanner() : Observable<any>{
    return this.http.get(this.url + 'publicity/get-banner/' + this.id_store).pipe(
      map(results => results)
    );
  }

  getDesign()  : Observable<any>{
    return this.http.get(this.url + 'design/get-by-id-store/' + this.id_store).pipe(
      map(results => results)
    );
  }

  //FidelityCard
  fidelityCardFull() : Observable<any>{
    let header = this._genAuthHeader();
    return this.http.get(this.url + 'user/fidelity-card-full', header).pipe(
      map(results => results)
    );
  }

  //Delivery
  getAddresses() : Observable<any>{
    let header = this._genAuthHeader();
    return this.http.get(this.url + 'address/app/get-user', header).pipe(
      map(results => results)
    );
  }

  deleteAddress(id_address) : Observable<any>{
    let header = this._genAuthHeader();
    return this.http.get(this.url + 'address/app/delete/' + id_address, header).pipe(
      map(results => results)
    );
  }

  addAddress(pkg) : Observable<any>{
    let header = this._genAuthHeader();
    return this.http.post(this.url + 'address/app/add', pkg, header).pipe(
      map(results => results)
    );
  }

  cartVerify(pkg): Observable<any>{
    let header = this._genAuthHeader();
    return this.http.post(this.url + 'cart/verify/' + this.id_store, pkg, header).pipe(
      map(results => results)
    );
  }

}
