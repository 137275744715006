import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class DesignService {
  public data;
  public styles;
  constructor(private api : ApiService, private storage : Storage) {
    this.styles = {}
    this.data = {}
    this.init();
  }

  async init(){
    this.storage.create();
    var design = await this.storage.get('design');
    if (design) {
      design = JSON.parse(design);
      if (design.id_store == this.api.getIdStore()) {
        this.data = design;
      }
    }
  }

  loadDesign(){
    this.api.getDesign().subscribe((data) => {
      this.data = data;
      this.storage.set('design', JSON.stringify(data));
      this.styles = {

        menu_category : {
          color : this.data.menu_text_category_color,
          fontFamily : this.data.font_title_family,
          fontSize : this.data.font_title_size + "px",
          backgroundColor : this.data.menu_background_category_color
        },
        title_style : {
          fontFamily : this.data.font_title_family,
          fontWeight : (this.data.font_title_bold == 1) ? 'bold': 'normal' ,
          fontStyle : (this.data.font_title_italic == 1) ? 'italic': 'normal' ,
        },
        title_sized : {
          fontFamily : this.data.font_title_family,
          fontWeight : (this.data.font_title_bold == 1) ? 'bold': 'normal' ,
          fontSize : this.data.font_title_size + "px",
          fontStyle : (this.data.font_title_italic == 1) ? 'italic': 'normal' ,
        },
        paragraph_style : {
          fontFamily : this.data.font_paragraph_family,
          fontWeight : (this.data.font_paragraph_bold == 1) ? 'bold': 'normal' ,
          fontStyle : (this.data.font_paragraph_italic == 1) ? 'italic': 'normal' ,
        },
        paragraph_sized : {
          fontFamily : this.data.font_paragraph_family,
          fontWeight : (this.data.font_paragraph_bold == 1) ? 'bold': 'normal' ,
          fontSize : this.data.font_paragraph_size + "px",
          fontStyle : (this.data.font_paragraph_italic == 1) ? 'italic': 'normal' ,
        },
        body : {
          backgroundImage : 'url("'+ this.data.body_img_link +'")',
          backgroundRepeat : (this.data.body_img_style == 1) ? "repeat" : "no-repeat",
          backgroundPosition : (this.data.body_img_style == 1) ? "auto" : "center",
          backgroundSize : (this.data.body_img_style == 1) ? "264px 264px" : "cover",
          backgroundAttachment: (this.data.body_img_style == 1) ? "auto" : "fixed"
        },
        card : {
          color : this.data.menu_text_color,
          backgroundColor : this.data.menu_background_color,
          background : this.data.menu_background_color
        },
        card_button : {
          backgroundColor : this.data.menu_button_background_color,
          color : this.data.menu_button_text_color
        },
        card_button_title : {
          backgroundColor : this.data.menu_button_background_color,
          color : this.data.menu_button_text_color,
          fontFamily : this.data.font_title_family,
          fontWeight : (this.data.font_title_bold == 1) ? 'bold': 'normal' ,
          fontStyle : (this.data.font_title_italic == 1) ? 'italic': 'normal' ,
        },
        login_input : {
          color : this.data.menu_text_color,
          borderColor : this.data.menu_text_color,
          backgroundColor : this.data.menu_background_color,
          fontFamily : this.data.font_paragraph_family,
        },
      }
    })
  }
}
